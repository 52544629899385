import * as React from 'react'
import { graphql } from 'gatsby'
import { Layout } from '../components/Layout'
import { Seo } from '../components/Seo'
import { HomepageBanner } from '../components/HomepageBanner'
import { SliceZone } from '@prismicio/react'
import { components } from "../slices";
import { Container } from 'react-bootstrap'
const Homepage = ({ data }) => {
  
  return (
    <Layout isHomepage>
      <Seo title="There are some Saas out there that you deserve!" />
      <HomepageBanner
      />
    </Layout>
  )
}


export default Homepage