import * as React from 'react'
import { Link } from 'gatsby'

import homeBanner from '../images/home-banner.jpg'
import { PrismicLink } from '@prismicio/react'
import { Container, Row, Col } from 'react-bootstrap'

export const HomepageBanner = (props) => (
	<Container>
		<div className='d-flex' style={{ height: "80vh" }}>
			<div className='my-auto'>
				<Row>
					<Col md={8}>
						<h1 className='big-title'>
							The Saas Blog
						</h1>

					</Col>
					
				</Row>
			</div>
		</div>
	</Container>

)
